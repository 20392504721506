<script lang="ts" setup>
const props = withDefaults(
	defineProps<{
		image?: string;
		imageMobile?: string;
		secondImage?: string;
		secondImageMobile?: string;
		bgColor?: string;
		bgImg?: string;
		bgImg2x?: string;
		type?: string;
		imageWidth?: number;
		imageHeight?: number;
		imageMobileWidth?: number;
		imageMobileHeight?: number;
		secondImageWidth?: number;
		secondImageHeight?: number;
		secondImageMobileWidth?: number;
		secondImageMobileHeight?: number;
	}>(),
	{
		image: "",
		imageMobile: "",
		secondImage: "",
		secondImageMobile: "",
		bgColor: "var(--secondary)",
		bgImg: "",
		bgImg2x: "",
		type: "",
		imageWidth: 2040,
		imageHeight: 600,
		imageMobileWidth: 1536,
		imageMobileHeight: 860,
		secondImageWidth: 1280,
		secondImageHeight: 644,
		secondImageMobileWidth: 1536,
		secondImageMobileHeight: 904
	}
);

const prepareImgUrl = useImage();

const {
	public: { baseImageUrl }
} = useRuntimeConfig();

const getImgFullPath = (src: string) => {
	const isNuxtImg = src?.includes("nuxt-img");
	return isNuxtImg ? src : `${baseImageUrl}${src}`;
};

const typeSet = new Set([
	"referral",
	"quest",
	"magicBox",
	"weekGame",
	"bannerPresetPackage",
	"flipTheCoin",
	"challenge",
	"season",
	"bannerTournament",
	"depositStreakBanner",
	"scratchBanner",
	"piggyBank"
]);
const background = props.bgColor;
const imagePattern = `url(${prepareImgUrl(`${baseImageUrl}${props.bgImg}`, { format: "avif" })})`;
const imagePattern2x = `url(${prepareImgUrl(`${baseImageUrl}${props.bgImg2x}`, { format: "avif" })})`;
</script>

<template>
	<section class="app-banner section--top-slider">
		<picture
			v-if="image || imageMobile"
			class="app-banner__img app-banner__img--one"
			:class="{ 'app-banner__img--one--hero': !typeSet.has(type) }"
		>
			<source
				:srcset="
					prepareImgUrl(getImgFullPath(imageMobile), {
						format: 'avif',
						width: props.imageMobileWidth,
						height: props.imageMobileHeight
					})
				"
				:width="props.imageMobileWidth"
				:height="props.imageMobileHeight"
				media="(max-width: 768px)"
			/>
			<NuxtImg
				:src="getImgFullPath(image)"
				format="avif"
				:width="props.imageWidth"
				:height="props.imageHeight"
				alt="App banner image"
			/>
		</picture>

		<picture
			v-if="secondImage || secondImageMobile"
			:class="['app-banner__img', 'app-banner__img--two', { 'app-banner__img--two--hero': !typeSet.has(type) }]"
		>
			<source
				:srcset="
					prepareImgUrl(getImgFullPath(secondImageMobile), {
						format: 'avif',
						width: props.secondImageMobileWidth,
						height: props.secondImageMobileHeight
					})
				"
				:width="props.secondImageMobileWidth"
				:height="props.secondImageMobileHeight"
				media="(max-width: 768px)"
			/>
			<NuxtImg
				:src="getImgFullPath(secondImage)"
				format="avif"
				:width="props.secondImageWidth"
				:height="props.secondImageHeight"
				alt="App banner image second"
			/>
		</picture>
		<div class="slide-item app-banner__content">
			<div class="app-banner__title">
				<slot />
			</div>
			<div class="app-banner__desc">
				<slot name="description" />
			</div>
			<div class="app-banner__actions">
				<slot name="actions" />
			</div>
		</div>
	</section>
</template>

<style lang="scss" scoped>
.app-banner {
	color: var(--neutral);
	position: relative;
	cursor: pointer;
	border-radius: 24px;
	height: 332px;
	display: flex;
	flex-direction: column;
	background-color: v-bind(background);
	background-image: v-bind(imagePattern);
	background-repeat: no-repeat;
	background-size: cover;
	@include media-breakpoint-down(md) {
		background-image: v-bind(imagePattern2x);
		border-radius: 16px;
		height: 100%;
	}

	&__img {
		position: absolute;
		line-height: 0;

		&--one {
			width: 100%;
			height: 100%;
			z-index: 2;
			right: 50%;
			top: 0;
			transform: translateX(50%);

			@include media-breakpoint-up(md) {
				right: 0;
				transform: none;
			}

			img {
				height: 100%;
				width: 100%;
				object-fit: cover;
				object-position: top center;
				border-radius: 16px;

				@include media-breakpoint-up(md) {
					object-position: right center;
					border-radius: 24px;
				}
			}
		}

		&--two {
			z-index: 3;
			right: 0;
			left: 0;
			bottom: 0;
			width: 100%;
			top: -22px;

			@include media-breakpoint-up(md) {
				right: 0;
				transform: none;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: top center;
				border-radius: 0 0 16px 16px;

				@include media-breakpoint-up(md) {
					object-fit: contain;
					object-position: center right;
					border-radius: 0 0 16px 16px;
				}
			}
		}
	}

	&__title {
		* > * {
			font-size: inherit;
			line-height: inherit;
			font-weight: inherit;
			font-style: inherit;
		}
	}

	&__content {
		z-index: 5;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-end;
		padding: 24px;
		max-width: none;
		height: 100%;
		text-align: center;

		@include media-breakpoint-up(md) {
			padding: 30px 50px;
			justify-content: center;
			align-items: flex-start;
			text-align: left;
		}
	}

	&__actions {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&__desc {
		display: flex;
		flex-direction: column;
		gap: 4px;
		margin: 2px 0 8px;

		@include media-breakpoint-up(md) {
			margin: 16px 0 21px;
		}
	}
}

:deep {
	p {
		margin: 0;
	}

	.app-banner {
		color: var(--neutral);

		&__btn {
			min-width: 220px;

			@include media-breakpoint-up(md) {
				width: 240px;
			}

			span {
				margin-left: gutter(1.25);
			}
		}

		&__desc-money {
			display: flex;
			align-items: center;

			@include media-breakpoint-down(md) {
				margin-top: 0;
				justify-content: center;
			}
		}

		&__desc-ico {
			width: 28px;
			height: 28px;
			margin-right: 4px;
		}
	}
}
</style>
